import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../../static/favicon-32x32.svg';

const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const title = props.title || data.site.siteMetadata.title;
      return (
        <Helmet
          htmlAttributes={{
            lang: 'en'
          }}
          title={title}
          titleTemplate={props.title ? `%s` : `%s - ${data.site.siteMetadata.title}`}
          link={[
            { rel: 'shortcut icon', type: 'image/svg', href: `${favicon}` }
          ]}
        />
      );
    }}
  />
);
const keywords = [
  "Law firm",
  "Legal services",
  "Lawyers",
  "Attorneys",
  "Legal advice",
  "Legal consultation",
  "Law office",
  "Legal representation",
  "Conveyancing services",
  "Family law",
  "Wills and probate",
  "Divorce lawyer",
  "Child custody lawyer",
  "Estate planning",
  "Property law",
  "Criminal law",
  "Business law",
  "Melbourne law firm",
  "Canberra lawyers",
  "Legal services in Melbourne",
  "Canberra family law",
  "Melbourne conveyancing",
  "Nationwide legal services",
  "Australia law firm",
  "Best family law firm in Melbourne",
  "Affordable legal consultation Canberra",
  "Top conveyancing services in Australia",
  "How to create a will in Canberra",
  "Melbourne divorce and child custody services",
  "Legal services for businesses in Australia",
  "Free consultation",
  "Trusted lawyers",
  "Experienced attorneys",
  "Professional legal advice",
  "Cost-effective legal solutions",
  "Contact us",
  "Get a quote",
  "Schedule a consultation",
  "Speak to a lawyer",
  "Request more information",
  'Punjabi Lawyer', 
  'Urdu Lawyer', 
  'Pakistani Lawyer', 
  'Indian Lawyer',
  'Muslim Lawyer'
];

SEO.defaultProps = {
  lang: 'en',
  meta: [
    { name: 'description', content: 'Unlock peace of mind with R & J Lawyers — your go-to for conveyancing, family law, wills, and probate. Serving Melbourne & Canberra. Get a free consultation today!' },
    { property: 'og:title', content: 'R & J Lawyers' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://randjlawyers.com.au/' },
    { property: 'og:image', content: 'https://randjlawyers.com.au/images/logo/logo-web264x64.svg' },
    { property: 'og:description', content: 'R & J lawyers are a law firm specialising in conveyancing, family law, wills and probate and many other legal services. We are based in Melbourne and Canberra but operate Nationwide.' }
  ],
  keywords: keywords
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
